import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import image6 from "../../assets/img/image-6.png";
import "./Team.css";

import nextIcon from "../../assets/icons/next.png";
import prevIcon from "../../assets/icons/prev.png";
import { useTranslation } from "react-i18next";
import { THE_FOUNDERS } from "../../data/dataFr";
import { Link } from "react-router-dom";

const Team = () => {
  const { t } = useTranslation();
  return (
    <div className="container team">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        navigation={{
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        }}
        modules={[Navigation]}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {THE_FOUNDERS.map((el, index) => (
          <SwiperSlide key={index}>
            <Link to="">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3>{t("MEET_WITH_OUR_TEAM")}</h3>
                  <h5>{el.subtitle}</h5>
                  <h6>{el.title}</h6>
                  <p>{el.content}</p>
                </div>
                <div className="col-md-6">
                  <img src={el.imageUrl} alt="" />
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-navigation" dir="ltr">
        <div className="custom-next">
          <img src={nextIcon} alt="Next" />
        </div>
        <div className="custom-prev">
          <img src={prevIcon} alt="Previous" />
        </div>
      </div>
    </div>
  );
};

export default Team;
