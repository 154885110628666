import React from 'react'
import './About.css'
import image1 from '../../assets/img/image-1.png'
import image2 from "../../assets/img/image-2.png";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about">
      <div className="container">
        <h2>{t("ABOUT_US")}</h2>
        <p>{t("ABOUT_US_BODY")}</p>
        <div className="row align-items-center mt-4">
          <div className="col-md-6">
            <h4>{t("OUR_VALUES")}</h4>
            <ul>
              <li>{t("OUR_VALUES_BODY")}</li>
              <li>{t("OUR_VALUES_BODY")}</li>
              <li>{t("OUR_VALUES_BODY")}</li>
            </ul>
            <a href="#">{t("OUR_COMPANY")}</a>
          </div>
          <div className="col-md-6">
            <img src={image1} alt="" />
          </div>
        </div>
        <div className="row align-items-center mt-4">
          <div className="col-md-6">
            <img src={image2} alt="" />
          </div>
          <div className="col-md-6">
            <h4>{t("LIFE_AT_DIGIPATTERN")}</h4>
            <ul>
              <li>{t("LIFE_AT_DIGIPATTERN_BODY")}</li>
              <li>{t("LIFE_AT_DIGIPATTERN_BODY")}</li>
              <li>{t("LIFE_AT_DIGIPATTERN_BODY")}</li>
            </ul>
            <a href="#">{t("OUR_COMPANY")}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About