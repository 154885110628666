import React from 'react'
import './Jobs.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import nextIcon from "../../assets/icons/next.png";
import prevIcon from "../../assets/icons/prev.png";
import { Navigation } from "swiper/modules";
import job1 from '../../assets/icons/job1.png'
import job2 from "../../assets/icons/job2.png";
import { JOB_OFFERS } from "../../data/dataFr";
import { useNavigate } from 'react-router-dom';





const Jobs = () => {
    const navigate = useNavigate();

  return (
    <div className="jobs container">
      <Swiper
        spaceBetween={30}
        loop={true}
        navigation={{
          nextEl: ".custom-next1",
          prevEl: ".custom-prev1",
        }}
        modules={[Navigation]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
        onSlideChange={() => console.log("slide change")}
        //   onSwiper={(swiper) => console.log(swiper)}
      >
        {JOB_OFFERS.map((el, index) => (
          <SwiperSlide key={index} style={{ width: "80%" }}>
            <div className="slide-content">
              <div>
                <img src={el.imageUrl } alt="" />
                <h4>{el.title}</h4>
                <h5>{el.subtitle}</h5>
                <p>{el.content}</p>
              </div>
              <button>View More Offer</button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-navigation" dir="ltr">
        <div className="custom-prev1">
          <img src={nextIcon} alt="Next" />
        </div>
        <div className="custom-next1">
          <img src={prevIcon} alt="Previous" />
        </div>
      </div>
    </div>
  );
}

export default Jobs