import React from "react";
import "./Header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/img/logo.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const Header = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    window.location.reload();
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };

  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ gap: "20px" }}
            navbarScroll
          >
            <NavLink to="/" className="nav-link" exact>
              {t("HOME")}
            </NavLink>
            <NavLink to="/our_company" className="nav-link">
              {t("WHO_WE_ARE")}
            </NavLink>
            <NavLink to="/1" className="nav-link">
              {t("WHAT_WE_DO")}
            </NavLink>
            <NavLink to="/2" className="nav-link">
              {t("DIGIPATTERN_LAB")}
            </NavLink>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {i18n.language === "fr" ? "langue" : "اللغة"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguageChange("ar")}>
                  العربية
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange("fr")}>
                  Français
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <button
              onClick={() =>
                handleLanguageChange(i18n.language === "fr" ? "ar" : "fr")
              }
              className="change_lng"
            >
              {i18n.language === "fr" ? "العربية" : "Français"}
            </button> */}
          </Nav>
          <a href="#contact-section" className="btn-contact">
            {t("CONTACT_US")}
          </a>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
