import React from 'react'
import './Our_company.css'
import image5 from '../../assets/img/image-5.png'
import Team from '../../component/Team/Team';
import Contact from '../../component/Contact/Contact';
import icon5 from '../../assets/icons/icon5.svg'
import HeroOurCompany from '../../component/HeroOurCompany/HeroOurCompany';
import Our_hostory from '../../component/Our_hostory/Our_hostory';

const Our_company = () => {
  return (
    <div className="our-company">
        <HeroOurCompany title='OUR COMPANY'/>
      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={image5} alt="" />
            </div>
            <div className="col-md-6">
              <h4>About our Company</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, <br />
                when an unknown printer took a galley of type and scrambled it
                to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s
                with the release of Letraset sheets containing Lorem Ipsum
                passages, and more recently with desktop publishing software
                like Aldus PageMaker including versions of Lorem Ipsum
              </p>
            </div>
          </div>
        </div>
      </div>
      <Our_hostory/>
      <Team />
      <section className="second-section container">
        <img src={icon5} alt="" />
        <div>
          <h4>Our Quality policy</h4>
          <p>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before the final copy is available. It is also
            used to temporarily replace text in a process called greeking, which
            allows designers to consider the form of a webpage or publicati
          </p>
        </div>
        <img src={icon5} alt="" />
      </section>
      <Contact />
    </div>
  );
}

export default Our_company