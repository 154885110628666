import React from 'react'
import './Home.css'
import About from '../../component/About/About'
import icon1 from '../../assets/icons/icon1.svg'
import icon2 from '../../assets/icons/icon2.svg'
import icon3 from "../../assets/icons/icon3.svg";
import icon5 from "../../assets/icons/icon5.svg";
import image1 from '../../assets/img/image-3.png'
import image14 from "../../assets/img/image-14.png";
import Contact from '../../component/Contact/Contact'
import Jobs from '../../component/Jobs/Jobs'
import { t } from 'i18next' 
import { useNavigate } from 'react-router-dom'


const Home = () => {
    const navigate = useNavigate();

  return (
    <div className="home">
      <div className="hero-home container">
        <img src={image14} alt="" />
        <h1>Specialist in Advanced Software Systems</h1>
      </div>
      <About />
      <section className="container">
        <div className="row">
          <div className="col-md-4">
            <div>
              <img src={icon1} alt="" />
              <h4>200+</h4>
              <p>Employs</p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src={icon2} alt="" />
              <h4>400+</h4>
              <p>Years of experience on average</p>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img src={icon3} alt="" />
              <h4>456+</h4>
              <p>Customers who put their trust in us</p>
            </div>
          </div>
        </div>
      </section>
      <div className="about">
        <div className="container">
          <div className="row align-items-center mt-4">
            <div className="col-md-6">
              <h4>{t("OUR_EXPERTISE")}</h4>
              <p>{t("OUR_EXPERTISE_BODY")}</p>
              <a href="#">Notre expertise</a>
            </div>
            <div className="col-md-6">
              <img src={image1} alt="" />
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-md-6">
              <img src={image1} alt="" />
            </div>
            <div className="col-md-6">
              <h4>{t("THE_LAB")}</h4>
              <p>{t("THE_LAB_BODY")}</p>
              <a href="#">En savoir plus</a>
            </div>
          </div>
        </div>
      </div>
      <section className="second-section container">
        <img src={icon5} alt="" />
        <div>
          <h4>{t("OUR_EMPLOYEES_TALENT")}</h4>
          <p>{t("OUR_EMPLOYEES_TALENT_BODY")}</p>
          <button onClick={()=>{
            navigate('/')
          }}>{t("JOIN_US")}</button>
        </div>
      </section>
      <Jobs />
      <Contact />
    </div>
  );
}

export default Home