import React, { useEffect, useState } from "react";
import './TheLab.css'
import { useParams } from "react-router-dom";
import HeroOurCompany from "../../component/HeroOurCompany/HeroOurCompany";
import Contact from "../../component/Contact/Contact";
import Achievements from "../../component/Achievements/Achievements";
import { LAB } from "../../data/dataFr";
import image from "../../assets/img/image-11.png";

const DinamiquePage = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const data = LAB.find((page) => page.id === id);
    setPageData(data);
  }, [id]);

  if (!pageData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <HeroOurCompany title={pageData.heroTitle} />
      <div className="the-lab container">
        <div className="hero-page">
          <img src={image} alt={pageData.title} />
        </div>
        <div className="description">
          <h3>About {pageData.title}</h3>
          <p>{pageData.description}</p>
          <div className="remarque">
            <p>{pageData.remarque}</p>
          </div>
        </div>
      </div>
      <Achievements achievements={pageData.achievements} />
      <Contact />
    </>
  );
};

export default DinamiquePage;
