import React from 'react'
import "./HeroOurCompany.css";
import icon5 from '../../assets/icons/icon5.svg'

const HeroOurCompany = ({title}) => {
  return (
    <div className="hero_our-company">
      <img src={icon5} alt="" className="first-img" />
      <h1>{title}</h1>
      <img src={icon5} alt="" className="second-img" />
    </div>
  );
}

export default HeroOurCompany