import "./App.css";
import Footer from "./component/Footer/Footer";
import Header from "./component/Header/Header";
import Home from "./pages/Home/Home";
import Our_company from "./pages/our_company/Our_company";
import "swiper/css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import i18n from "./i18n";
import DinamiquePage from "./pages/TheLab/DinamiquePage";

function App() {
  return (
    <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our_company" element={<Our_company />} />
          <Route path="/:id" element={<DinamiquePage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
